import { get, isNull, omitBy } from "lodash";
const defaultState = {
    fetching: false,
    error: false,
    status: 0,
    servererror: [],
    data: {},
    message: "Your attempt was not successful, please contact our support",
    navbarHeight: 0,
    screenHeight: 0,
    screenWidth: 0,
    paMember: {}
}

export default function storageReducer(state = defaultState, action) {
    switch (action.type) {
        case "FETCH_PROFILE_PENDING": {
            return {
                ...defaultState,
                fetching: true,
            }
        }
        case "FETCH_PROFILE_REJECTED": {
            const { errors = false, detail = false } = get(action, "payload.response.data", {})
            return {
                ...defaultState,
                fetching: false,
                status: 400,
                error: errors ? errors : (detail ? detail : defaultState["message"])
            }
        }
        case "FETCH_PROFILE_FULFILLED": {
            const data = omitBy(action.payload, isNull);
            return {
                ...state,
                data: {
                    ...data,
                    rolePermissionsArray: get(data, "role.permissions", []),
                },
                fetching: false,
                status: 200
            }
        }
        case "UPDATE_STORE": {
            return {
                ...state,
                ...action.payload,
            }
        }
        default: {
            return state;
        }
    }
}